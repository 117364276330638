import React, { useEffect, useState, useContext } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { useLocation } from '@reach/router';
// queries
import { RECOMMENDED_PRODUCTS } from '../../queries/user';
// components
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Reviews from '@components/reviews/reviews';
import HowItWorks from '../../components/how-it-works';
import AddToBasket from '../../components/add-to-basket';
import Image from '@components/image/image';
import Loader from '../../components/loader';
// context
import { GlobalContext } from '@store/global-state';
import { ERROR_MESSAGES } from '../../constants';
// services
import { isUsingGBPCurrency } from '../../services/global';
import { trackProductViewed } from '../../services/tracking/tracking';
import { OFFER_CODES, PRODUCT_COOKIES } from '../../constants';
// styles
import styles from './essential-one.module.scss';
import { formatPrice } from './../../services/format';
// hooks
import { useProductPlansLazyQuery } from '../../hooks/useProductPlans';
import { SwiperComponent } from 'vitl-component-library';
import couponCodeService from '@src/utils/couponCodeService';
import { essentialPageVisitSetCookie } from '@src/utils/coupons';

interface IProps {
  isLandingPage?: boolean;
  isFreeTrial?: boolean;
  partnership: string;
  noindex?: boolean;
}

const EssentialOnePage: React.FC<IProps> = ({
  isLandingPage = false,
  isFreeTrial = false,
  partnership = 'no-partnership',
  noindex = false,
}) => {
  const [arePlansAvailable, setPlansAvailable] = useState<null | boolean>(false);
  const content = useStaticQuery(graphql`
    query EssentialOne {
      directusEssentialOne {
        page_title
        page_title_trial
        page_description
        page_description_trial
        hero_title
        hero_title_trial
        hero_description
        hero_description_trial
        hero_description_20off_trial
        hero_description_free_trial
        hero_description_essential_one_free_trial
        hero_description_referred
        hero_description_no_price
        hero_button_label
        hero_image {
          title
          filename_disk
        }
        hero_footnote
        made_for_you_title
        made_for_you_description
        made_for_you_person_1_image_1 {
          filename_disk
          title
        }
        made_for_you_person_1_image_2 {
          filename_disk
          title
        }
        made_for_you_person_1_image_mobile {
          filename_disk
          title
        }
        made_for_you_person_2_image_1 {
          filename_disk
          title
        }
        made_for_you_person_2_image_2 {
          filename_disk
          title
        }
        made_for_you_person_2_image_mobile {
          filename_disk
          title
        }
        made_for_you_person_3_image_1 {
          filename_disk
          title
        }
        made_for_you_person_3_image_2 {
          filename_disk
          title
        }
        made_for_you_person_3_image_mobile {
          filename_disk
          title
        }
        how_it_works_title
        how_it_works_items {
          title
          description
          filename_disk
        }
        best_ingredients_title
        best_ingredients_description
        best_ingredients_free_from_new {
          text
        }
        reviews_title
        reviews_items {
          reviewerName
          review
        }
        personal_best_title
        personal_best_title_trial
        personal_best_description
        personal_best_description_no_price
        personal_best_description_trial
        personal_best_image {
          filename_disk
          title
        }
      }
      directusSetting {
        logo {
          title
          filename_disk
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const { setErrorModalMessage, isLoggedIn } = useContext(GlobalContext);
  const { setOffer } = couponCodeService();

  const [getRecommendedProducts, { data: dataProducts, loading: loadingProducts }] = useLazyQuery(
    RECOMMENDED_PRODUCTS,
    {
      fetchPolicy: 'network-only',
      onError: () => {
        setErrorModalMessage(ERROR_MESSAGES.getRecommendedProducts);
      },
    }
  );

  const [getPlans, { data: dataPlans, loading: loadingPlans }] = useProductPlansLazyQuery({
    onCompleted: () => {
      setPlansAvailable(dataPlans.product_plans.length > 0);
    },
    onError: () => {
      setErrorModalMessage(ERROR_MESSAGES.getProductPlans);
    },
  });

  const essentialOneRecommendation = dataProducts?.health_recommendedProducts?.find(
    (product: any) => product.id.startsWith('essential-one')
  );

  let plans = essentialOneRecommendation
    ? dataPlans?.product_plans?.filter((item: any) =>
        item?.sku?.replace('-monthly-30', '')?.includes(essentialOneRecommendation?.id)
      )
    : null;

  // Make sure we always use the first element from the array
  plans = plans && plans.length > 0 && [plans[0]];
  const price = dataPlans?.product_plans[0].rrpPriceLabel;
  const essentialOnePrice = plans ? formatPrice(plans[0]?.rrpPrice, plans[0]?.currency) : price;

  useEffect(() => {
    if (isLoggedIn) getRecommendedProducts();
  }, [isLoggedIn]);

  useEffect(() => {
    getPlans({
      variables: {
        productId: 'essential-one',
      },
    });
    trackProductViewed({
      name: 'Essential One',
      url: location.href,
      variant: essentialOneRecommendation?.id,
      product_id: 'essential-one',
      category: 'essential',
    });
  }, []);

  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.split('/').some(x => x === 'product')) {
      if (partnership === PRODUCT_COOKIES.ESSENTIAL_ONE.value.ESSENTIAL_ONE_20_OFF_FREE_TRIAL) {
        setOffer('essential', '20off');
      }
      if (partnership === PRODUCT_COOKIES.ESSENTIAL_ONE.value.AFFILIATES) {
        setOffer('essential', 'none', 'affiliate');
      }
    }

    if (partnership === 'no-partnership') {
      essentialPageVisitSetCookie();
    }
  }, []);

  const { directusEssentialOne, site, directusSetting } = content;
  const createSlide = (image_1: any, image_2: any) => (
    <>
      <Image width={400} alt={image_1.title} filename={image_1.filename_disk} />
      <Image width={400} alt={image_2.title} filename={image_2.filename_disk} />
    </>
  );

  const getHeroDescription = () => {
    switch (partnership) {
      case PRODUCT_COOKIES.ESSENTIAL_ONE.value.ESSENTIAL_ONE_20_OFF_FREE_TRIAL:
        return directusEssentialOne.hero_description_20off_trial;
      case PRODUCT_COOKIES.ESSENTIAL_ONE.value.TRIAL:
        return directusEssentialOne.hero_description_free_trial;
      case PRODUCT_COOKIES.ESSENTIAL_ONE.value.REFERRAL:
        return directusEssentialOne.hero_description_referred;
      case PRODUCT_COOKIES.ESSENTIAL_ONE.value.AFFILIATES:
        return directusEssentialOne.hero_description_essential_one_free_trial.replace(
          '{price}',
          essentialOnePrice
        );
      default:
        return (isLandingPage || isFreeTrial) && isUsingGBPCurrency()
          ? directusEssentialOne.hero_description_trial
          : !essentialOnePrice
          ? directusEssentialOne.hero_description.replace('{price}', essentialOnePrice)
          : directusEssentialOne.hero_description_no_price;
    }
  };

  const toggleCallToAction = () => {
    if (plans) {
      return (
        <div>
          <AddToBasket
            plans={plans}
            isFreeTrial={isFreeTrial}
            productType={OFFER_CODES.EssentialOne}
          />
          {isUsingGBPCurrency() && (
            <div
              className={styles.footnote}
              dangerouslySetInnerHTML={{
                __html: directusEssentialOne.hero_footnote,
              }}
            />
          )}
        </div>
      );
    } else {
      return (
        <div>
          <Link className={styles.button} to={'/consultation'}>
            {directusEssentialOne.hero_button_label}
          </Link>
          <p className={styles.footnote}>
            {isUsingGBPCurrency() && (
              <span>
                Free UK delivery
                <br />
              </span>
            )}
            Pause or cancel subscription any time
          </p>
        </div>
      );
    }
  };

  if (loadingPlans || loadingProducts) return (
    <>
      <SEO
        noindex={noindex}
        title={
          isFreeTrial ? directusEssentialOne.page_title_trial : directusEssentialOne.page_title
        }
        description={
          isFreeTrial
            ? directusEssentialOne.page_description_trial
            : directusEssentialOne.page_description
        }
      />
      <Loader />
    </>
  );

  return (
    <Layout showFooter={isLandingPage ? false : true} showHeader={isLandingPage ? false : true}>
      <SEO
        noindex={noindex}
        title={
          isFreeTrial ? directusEssentialOne.page_title_trial : directusEssentialOne.page_title
        }
        description={
          isFreeTrial
            ? directusEssentialOne.page_description_trial
            : directusEssentialOne.page_description
        }
      />

      {isLandingPage && (
        <Link to="/">
          <Image
            className={styles.logo}
            width={60}
            filename={directusSetting.logo.filename_disk}
            alt={site.siteMetadata.title}
          />
        </Link>
      )}

      <section className={styles.sectionHeroWhite}>
        <div className={styles.heroContainer}>
          <div className={styles.heroRow}>
            <div className={styles.imageLeftLarge}>
              <Image
                filename={directusEssentialOne.hero_image.filename_disk}
                alt={directusEssentialOne.hero_image.title}
                width={500}
              />
            </div>
            <div className={styles.contentWrapperLeft}>
              <div className={styles.heroContent}>
                <h1>
                  {(isLandingPage || isFreeTrial) && isUsingGBPCurrency()
                    ? directusEssentialOne.hero_title_trial
                    : directusEssentialOne.hero_title}
                </h1>
                <hr className={styles.hr} />
                <div className={styles.mobileHeroImage}>
                  <Image
                    filename={directusEssentialOne.hero_image.filename_disk}
                    alt={directusEssentialOne.hero_image.title}
                    width={500}
                  />
                </div>
                <div
                  className={styles.heroDescription}
                  dangerouslySetInnerHTML={{ __html: getHeroDescription() }}
                />
                {toggleCallToAction()}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.sectionPrimaryLighter}>
        <div className={styles.container}>
          <div className={styles.rowCentered}>
            <h2>{directusEssentialOne.made_for_you_title}</h2>
            <hr />
            <div
              className={styles.madeForYou}
              dangerouslySetInnerHTML={{
                __html: directusEssentialOne.made_for_you_description,
              }}
            />
            <SwiperComponent paginationType="bullets" spaceBetween={8} slidesPerView={1}>
              {createSlide(
                directusEssentialOne.made_for_you_person_1_image_1,
                directusEssentialOne.made_for_you_person_1_image_2
              )}
              {createSlide(
                directusEssentialOne.made_for_you_person_2_image_1,
                directusEssentialOne.made_for_you_person_2_image_2
              )}
              {createSlide(
                directusEssentialOne.made_for_you_person_3_image_1,
                directusEssentialOne.made_for_you_person_3_image_2
              )}
            </SwiperComponent>
          </div>
        </div>
      </section>

      <section className={styles.sectionFullScreen}>
        <HowItWorks
          title={directusEssentialOne.how_it_works_title}
          items={directusEssentialOne.how_it_works_items}
        />
      </section>

      <section className={styles.sectionPrimaryLighter}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.content}>
              <div>
                <h2>{directusEssentialOne.best_ingredients_title}</h2>
                <hr />
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: directusEssentialOne.best_ingredients_description,
                }}
              />
              <div className={styles.icons}>
                <div>
                  <Image src="/images/icon-boxed-personalised.svg" width={45} alt="Personalised" />
                  Personalised
                </div>
                <div>
                  <Image src="/images/icon-boxed-vegan.svg" width={45} alt="Vegan friendly" />
                  Vegan friendly
                </div>
                <div>
                  <Image src="/images/icon-boxed-non-gmo.svg" width={45} alt="Non-GMO" />
                  Non-GMO
                </div>
              </div>
            </div>
            <div className={styles.content}>
              <h5> Free from</h5>
              <div className={styles.bestIngredientsDescription}>
                {directusEssentialOne.best_ingredients_free_from_new.map(
                  (x: { text: string }, index: number) => (
                    <p key={index}>
                      <Image
                        src="/images/icon-check-circle.svg"
                        width={25}
                        className={styles.tickIcon}
                      />
                      {x.text}
                    </p>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.sectionFullScreen}>
        <Reviews
          title={directusEssentialOne.reviews_title}
          items={directusEssentialOne.reviews_items}
          showReview
          hideTrustPilot
        />
      </section>

      <section className={styles.sectionPrimaryLighterNoPadding}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.imageRightSmall}>
              <Image
                filename={directusEssentialOne.personal_best_image.filename_disk}
                alt={directusEssentialOne.personal_best_image.title}
                width={264}
              />
            </div>
            <div className={styles.contentWrapperRight}>
              <div className={styles.contentSectionCenter}>
                <h2>
                  {(isLandingPage || isFreeTrial) && isUsingGBPCurrency()
                    ? directusEssentialOne.personal_best_title_trial
                    : directusEssentialOne.personal_best_title}
                </h2>
                <hr className={styles.hrCentered} />
                <div className={styles.heroDescription}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        (isLandingPage || isFreeTrial) && isUsingGBPCurrency()
                          ? directusEssentialOne.personal_best_description_trial
                          : !essentialOnePrice
                          ? directusEssentialOne.personal_best_description_no_price
                          : directusEssentialOne.personal_best_description.replace(
                              '{price}',
                              essentialOnePrice
                            ),
                    }}
                  />
                  {plans ? (
                    <AddToBasket
                      plans={plans}
                      isFreeTrial={isFreeTrial}
                      productType={OFFER_CODES.EssentialOne}
                    />
                  ) : (
                    <Link className={styles.button} to={'/consultation'}>
                      {directusEssentialOne.hero_button_label}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default EssentialOnePage;
